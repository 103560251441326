.css-2b097c-container {
  min-width: 300px;
}

.chip {
  &-old {
    background-color: #d82c0d;
    padding: 6px 32px;
    width: max-content;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    margin: 0 auto;
  }

  &-new {
    background-color: #2c6ecb;
    width: max-content;
    padding: 6px 32px;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    margin: 0 auto;
  }
}
